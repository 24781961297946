const Settings = {
    env: "stg",
    webUrl: "https://revtel-test.revtel.tech",
    apiUrl: "https://stg.hecafe-api.com",
    mediaHost: "https://dvkvars7ubgms.cloudfront.net",
    s3Host: "https://s3-ap-northeast-1.amazonaws.com/stg.hecafe.com.tw",
    debug: true,
    facebookAppId: "1276459929127208",
    googleClientId: "115128487422-bs5m8q90u3g4ata8lb9ujorusgpqhefj.apps.googleusercontent.com",
    googleAnalyticsId: "UA-165938809-2",
    userType: "aws",
    gtmId: '',
    slsApi: {
      host: "http://localhost:3000",
      bigTableHost: "https://gfm01off27.execute-api.ap-northeast-1.amazonaws.com/stg",
    },
    revBlogServiceApi: "https://blog.revtel-api.com"
};

module.exports = Settings;

