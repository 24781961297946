import React, { Component } from "react";
import "./index.css";
import styled from "styled-components";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import ContactForm from "./Components/ContactForm";
import Delighters from "./Components/ReactDelighters";
import { Helmet } from "react-helmet";
import { setWindowObj, isDesktop, isPhone } from "./Utils/WindowUtil.js";
import { connect } from "react-redux";
import ActionCreator from "./ActionCreator";

import LanguageSettingIcon from "./Components/LanguageSettingIcon";

// const navbarWidth = 60;
const navbarHeight = 60;
const footerHeight = 60;
const mobileFooterHeight = 150;

class Layout extends Component {
  state = {
    isPhone: isPhone(),
  };

  componentDidMount() {
    window.addEventListener("scroll", this._onScroll);
    setWindowObj(window);
    this.setState({ isPhone: isPhone() });

    this.props.appActions.setLang(
      this.props.location.pathname.indexOf("/en") !== -1 ? "en" : "tw"
    );

    // add adsense
    try {
      const script = document.createElement("script");
      script.src =
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      script.async = true;
      script["data-ad-client"] = "ca-pub-9251535461070067";
      document.body.appendChild(script);
    } catch (e) {
      console.warn("append adsense fail", e);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll);
  }

  render() {
    let {
      location: { pathname, href },
      pageContext: { keywords },
    } = this.props;
    let passBreakpoint = !!this.passBreakpoint;
    pathname = this._normalizePath(pathname);

    let withPaddingTop = true;
    let showFooter = true;
    let showNavbar = true;

    if (pathname === "/") {
      withPaddingTop = false;
    } else if (
      pathname === "/branding/" ||
      pathname.indexOf("/branding/") === 0
    ) {
      showFooter = false;
      showNavbar = false;
    }

    return (
      <Delighters>
        <Wrapper withPaddingTop={withPaddingTop}>
          <Helmet>
            <title>RevtelTech 忻旅科技股份有限公司</title>
            <meta
              name="description"
              content="顧問(Consult)/開發(Development)/營運(Operation) | Web/APP/IoT/Blockchain | React Native / GatsbyJS"
            />
            <meta
              name="google-site-verification"
              content="VgMfxef8huYCYeEyLbKCsyulVnHtsmXRNShI30tvOnU"
            />
            <meta name="image" content="/images/og-image.png" />
            <meta property="og:url" content={href} />
            <meta property="og:title" content="RevtelTech 忻旅科技" />
            <meta
              name="facebook-domain-verification"
              content="gjatokfbr72a3crc02prhnzf2r1eyp"
            />
            <meta
              name="facebook-domain-verification"
              content="97n308kxq9inq9pgf8nzsim6eq90m1"
            />
            <meta
              name="og:description"
              content="顧問(Consult)/開發(Development)/營運(Operation) | Web/APP/IoT/Blockchain"
            />

            <meta property="og:type" content="website" />
            <meta property="og:image" content="/images/og-image.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="3063" />
            <meta property="og:image:height" content="3375" />

            {keywords && <meta property="keywords" content={keywords} />}

            <link rel="shortcut icon" href="/images/logo.png" />
            {/* font-family: 'Barlow Condensed' 'Barlow Semi Condensed' 'Noto Sans TC'; */}

            <link
              rel="preload"
              href="https://fonts.googleapis.com/css?family=Barlow+Condensed:600|Barlow+Semi+Condensed:200|Noto+Sans+TC:400|Noto+Sans+TC:300&display=swap"
              as="style"
              onload="this.rel='stylesheet'"
            />
            <noscript>
              {
                '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Barlow+Condensed:600|Barlow+Semi+Condensed:200|Noto+Sans+TC:400|Noto+Sans+TC:300&display=swap" />'
              }
            </noscript>
          </Helmet>

          {showNavbar && (
            <Navbar
              navbarHeight={navbarHeight}
              pathname={pathname}
              passBreakpoint={passBreakpoint || withPaddingTop}
            />
          )}

          <Content showNavbar={showNavbar} showFooter={showFooter}>
            {this.props.children}
          </Content>

          {showFooter && (
            <Footer
              footerHeight={footerHeight}
              mobileFooterHeight={mobileFooterHeight}
              pathname={pathname}
              isPhone={this.state.isPhone}
            />
          )}
          <ContactForm />
        </Wrapper>
      </Delighters>
    );
  }

  _normalizePath = path => {
    if (path.slice(0, 2) === "//") {
      path = path.slice(1);
    }

    if (path[path.length - 1] !== "/") {
      path += "/";
    }

    return path;
  };

  _onScroll = e => {
    const breakpoint = 600;
    let scrollTop = document.querySelector("html").scrollTop;

    if (scrollTop > breakpoint) {
      if (!this.passBreakpoint) {
        this.passBreakpoint = true;
        this.forceUpdate();
      }
    } else {
      if (this.passBreakpoint) {
        this.passBreakpoint = false;
        this.forceUpdate();
      }
    }
  };
}

const Wrapper = styled.div`
  min-height: calc(100vh);
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding-top: ${props => (props.showNavbar ? navbarHeight : 0)}px;
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 500px) {
  }
`;

Layout = connect(() => ({}), ActionCreator)(Layout);

export default Layout;
