import React, { Component } from "react";
import styled from "styled-components";
import { HeaderFontCss, ParagraphFontCss } from "../Utils/CssStyleTemplateUtil";
import { StaticImage } from "./StaticImage";
import * as Widget from "./Widget";
import { s, d } from "../Utils/Lang";

class Footer extends Component {
  render() {
    return (
      <Wrapper>
        <div className="container">
          <div className="top">
            <div className="slogan">{s("slogan")}</div>
            <div className="logo">
              <StaticImage
                style={{ weight: 200, height: "100%" }}
                name={"revtel-logo-color.png"}
              />
            </div>
          </div>
          <div className="bottom">
            <div className="contact">
              <div className="social-media">
                <a
                  target="_blank"
                  href={"https://www.facebook.com/RevtelTech/"}
                >
                  <StaticImage
                    style={{
                      width: 41,
                      height: 41,
                      cursor: "pointer",
                      marginRight: 12.5,
                    }}
                    name={"icon-facebook.png"}
                  />
                </a>
                <a target="_blank" href={"https://medium.com/@revteltech"}>
                  <StaticImage
                    style={{
                      width: 41,
                      height: 41,
                      cursor: "pointer",
                      marginRight: 12.5,
                    }}
                    name={"icon-medium.png"}
                  />
                </a>
                <a target="_blank" href={"https://lin.ee/Qg2AUocY"}>
                  <StaticImage
                    style={{
                      width: 41,
                      height: 41,
                      cursor: "pointer",
                      marginRight: 12.5,
                    }}
                    name={"icon-line.png"}
                  />
                </a>
                <a target="_blank" href={"https://github.com/revtel"}>
                  <StaticImage
                    style={{ width: 41, height: 41, cursor: "pointer" }}
                    name={"icon-github.png"}
                  />
                </a>
              </div>
              <div className="phone">
                <a
                  href="tel:+886225578895"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  (02)2557-8895
                </a>
              </div>
              <div className="email">
                <a
                  href="mailto:contact@revteltech.com"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  contact@revteltech.com
                </a>
              </div>
            </div>
            <div className="copyright">2021 Copyright. All Rights Reserved</div>
          </div>
        </div>
        <div className="linear-gradient-line" />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 64px 0px 0px;
  min-height: 300px;
  height: 100%;
  position: relative;
  box-shadow: 0px -4px 15px 0 rgba(0, 0, 0, 0.12);

  & .container {
    margin: 0 auto;
    max-width: 1187px;
    padding: 10px 20px;
  }

  & .linear-gradient-line {
    width: 100vw;
    height: 10px;
    background-image: linear-gradient(61deg, #faa34e 23%, #f36a26 90%);
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.22);
    position: absolute;
    bottom: 0px;
  }

  & .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .slogan {
      border-right: 1px solid #e0e0e0;
      padding-right: 63.5px;
      flex: 1;
      font-size: 35px;
      color: #4e4e4e;
      letter-spacing: 1px;
      line-height: 40px;
      ${ParagraphFontCss}
    }

    & > .logo {
      width: 250.5px;
      padding: 16px 50px;
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;

      & > .slogan {
        border-right: 0px;
        padding-right: 0px;
        margin-bottom: 20px;
      }
    }
  }

  & .bottom {
    border-top: 1px solid #e0e0e0;
    margin-top: 30px;
    padding: 20px 0px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 14px;
    color: #4e4e4e;
    letter-spacing: 0.4px;
    line-height: 20px;
    ${ParagraphFontCss}

    & .contact {
      display: flex;
      align-items: center;

      & > .social-media {
        min-width: 184px;
        display: flex;
      }

      & > .phone {
        min-width: 131px;
        padding: 10px;
      }

      & > .email {
        min-width: 182px;
        padding: 10px;
      }
    }

    & .copyright {
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;

      & .contact {
        flex-direction: column;
        justify-content: center;

        text-align: center;

        & > .social-media {
          justify-content: center;
          margin-bottom: 30px;
        }

        & > .phone {
          margin-bottom: 10px;
          padding: 0px;
        }

        & .email {
          margin-bottom: 10px;
          padding: 0px;
        }
      }

      & .copyright {
        text-align: center;
      }
    }
  }
`;

export default Footer;
