import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "gatsby";
import ActionCreator from "../ActionCreator";
import { s, d } from "../Utils/Lang";
import { HeaderFontCss } from "../Utils/CssStyleTemplateUtil";
import * as Icon from "./Icon";
import Selectors from "../Selectors";
import { StaticImage } from "./StaticImage";
import LanguageSettingIcon from "./LanguageSettingIcon";

const background = "#e6e6e6";
const altBackground = "#e6e6e6";
const textColor = "#4E4E4E";
const breakpointHorizontal = 800;
const navItems = [
  { children: "about", url: "/about/" },
  { children: "history", url: "/history/" },
  { children: "project", url: "/project/" },
  {
    children: "services",
    url: "https://revtel.pse.is/revtelapp-from-official-website",
    color: "#CC4618",
  },
  {
    children: "sharing",
    url: "https://revtel.pse.is/medium-from-official-website",
    urlEn: "https://revtel.pse.is/medium-en-from-official-website",
    color: "#C49609",
  },
  { children: "industry", url: "/industry/" },
  { children: "product", url: "/product/" },
];

class Navbar extends React.Component {
  state = {
    openMenu: false,
    lang: "tw",
  };

  componentDidMount() {
    let { lang } = this.props;
    this.setState({ lang });
  }

  render() {
    let { navbarHeight, passBreakpoint, pathname } = this.props;

    return (
      <Style.Wrapper navbarHeight={navbarHeight}>
        {this._smallScreenNavBar()}
        {this._largeScreenNavBar()}
      </Style.Wrapper>
    );
  }

  _smallScreenNavBar() {
    let { pathname, appActions, navbarHeight } = this.props;
    const toggleMenuState = () =>
      this.setState({ openMenu: !this.state.openMenu });
    return (
      <Style.SmallScreenWrapper breakpoint={breakpointHorizontal}>
        <div className="header-content">
          <Link to={"/"}>
            <img style={{ width: 41, height: 41 }} src="/images/logo.png" />
          </Link>
          <Link to={"/"}>
            <img
              style={{ width: 150, height: 41, marginLeft: 15 }}
              src={
                this.state.lang === "tw"
                  ? "/images/company-name.png"
                  : "/images/RevText.png"
              }
            />
          </Link>
          <div
            style={{
              width: 40,
              height: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={toggleMenuState}
          >
            <Icon.Apps color={textColor} size={24} />
          </div>
        </div>

        <Style.SmallScreenMenu
          open={this.state.openMenu}
          navbarHeight={navbarHeight}
          onClick={toggleMenuState}
        >
          <div className="container">
            <div className="menu-header">
              <Link to={this._withLangPrefix("/")}>
                <img style={{ width: 41, height: 41 }} src="/images/logo.png" />
              </Link>
              <Link to={"/"}>
                <img
                  style={{ width: 150, height: 41, marginLeft: 15 }}
                  src={
                    this.state.lang === "tw"
                      ? "/images/company-name.png"
                      : "/images/RevText.png"
                  }
                />
              </Link>
              <div
                style={{ width: 40, height: 40, cursor: "pointer" }}
                onClick={toggleMenuState}
              >
                <Icon.Close color={textColor} size={24} />
              </div>
            </div>

            <Link
              to={this._withLangPrefix("/")}
              className={`nav-item`}
              style={{ justifyContent: "center", marginLeft: 0 }}
            >
              <p>{s("home")}</p>
            </Link>

            {[...navItems]
              //.reverse()
              .map(({ children, url, urlEn, color = "black" }, idx) => {
                let isActive = pathname === url;

                if (url.indexOf("http") >= 0) {
                  return (
                    <a
                      aria-label={s(`${children}-mobile`)}
                      href={this.state.lang === "en" && urlEn ? urlEn : url}
                      target="_blank"
                      key={idx}
                      className={`nav-item` + (isActive ? ` active` : "")}
                      style={{ justifyContent: "center", marginLeft: 0 }}
                    >
                      <p style={{ color }}>
                        <b>{s(`${children}-mobile`)}</b>
                      </p>
                    </a>
                  );
                }

                return (
                  <Link
                    to={this._withLangPrefix(url)}
                    key={idx}
                    className={`nav-item` + (isActive ? ` active` : "")}
                    style={{ justifyContent: "center", marginLeft: 0 }}
                  >
                    <p>{s(`${children}-mobile`)}</p>
                  </Link>
                );
              })}

            <div
              className={`nav-item contact`}
              onClick={appActions.toggleContactForm}
              style={{ justifyContent: "center", marginLeft: 0 }}
            >
              <p>{s("contact-us")}</p>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <LanguageSettingIcon simpleMode style={{ marginTop: 10 }} />
            </div>
          </div>
        </Style.SmallScreenMenu>
      </Style.SmallScreenWrapper>
    );
  }

  _largeScreenNavBar() {
    let { pathname, appActions } = this.props;
    return (
      <Style.LargeScreenWrapper breakpoint={breakpointHorizontal}>
        <Link to={this._withLangPrefix("/")} className="logo">
          <img style={{ width: 41, height: 41 }} src="/images/logo.png" />
          <img
            style={{ width: 150, height: 41, marginLeft: 15 }}
            src={
              this.state.lang === "tw"
                ? "/images/company-name.png"
                : "/images/RevText.png"
            }
          />
        </Link>
        <div style={{ flex: 1 }}></div>
        {navItems.map(({ children, url, urlEn, color = "black" }, idx) => {
          let isActive = pathname.indexOf(url) > -1 && url !== "/";
          if (url.indexOf("http") >= 0) {
            return (
              <a
                aria-label={s("children")}
                href={this.state.lang === "en" && urlEn ? urlEn : url}
                target="_blank"
                key={idx}
                className={`nav-item` + (isActive ? ` active` : "")}
                style={{ justifyContent: "center", marginLeft: 0 }}
              >
                <p style={{ color }}>
                  <b>{s(`${children}`)}</b>
                </p>
              </a>
            );
          }
          return (
            <Link
              to={this._withLangPrefix(url)}
              key={idx}
              className={`nav-item` + (isActive ? ` active` : "")}
            >
              {s(children)}
            </Link>
          );
        })}

        <div style={{ marginRight: 20 }}>
          <LanguageSettingIcon menuOffset={15} />
        </div>

        <div
          className={"nav-item cta-button"}
          onClick={appActions.toggleContactForm}
        >
          {s("contact-us")}
        </div>
      </Style.LargeScreenWrapper>
    );
  }

  _withLangPrefix(link) {
    if (this.props.lang === "en") {
      return "/en" + link;
    } else {
      return link;
    }
  }
}

const Style = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: white;
    width: 100vw;
    height: ${props => props.navbarHeight}px;
    box-shadow: 0px 4px 15px 0 rgba(0, 0, 0, 0.12);
    @media screen and (max-width: ${breakpointHorizontal}px) {
      width: 100vw;
      height: ${props => props.navbarWidth}px;
      right: 0;
      left: auto;
      z-index: 12; /* should be higher than footer's z-index: 10 in mobile mode */
    }
  `,

  LargeScreenWrapper: styled.div`
    @media screen and (max-width: ${props => props.breakpoint || 900}px) {
      display: none;
    }
    height: 100%;

    flex: 1;
    display: flex;
    align-items: center;

    & > .logo {
      display: flex;
      flex-grow: 1;
      padding-left: 15px;
    }

    & > .nav-item {
      padding: 0px 24px;
      display: flex;
      font-size: 15px;
      ${HeaderFontCss}
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      color: ${textColor};
      text-decoration: none;
      display: flex;
      background-color: #fff;
      letter-spacing: 4px;
    }

    & > .nav-item.active {
      color: #f36a26;
    }

    & > .nav-item.cta-button {
      cursor: pointer;
      padding: 7px 24px;
      padding-right: 20px; /* offset for letter-spacing: 4px; */
      background-image: linear-gradient(64deg, #faa34e 1%, #f36a26 88%);
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
      border-radius: 17px;
      color: white;
      align-self: center;
      margin-right: 15px;
    }
  `,

  LargeScreenItem: styled.div`
    padding: 0px 25px;
  `,

  SmallScreenWrapper: styled.div`
    @media screen and (min-width: ${props => props.breakpoint || 768}px) {
      display: none;
    }

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding: 10px 15px;

    & .header-content {
      display: flex;
      flex: 1;
      justify-content: space-between;
    }
  `,

  SmallScreenMenu: styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: ${props => (props.open ? "0" : "-100vw")};
    background-color: ${props =>
      props.open ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0)"};
    transition: background-color 250ms linear,
      left 0ms linear ${props => (props.open ? "0ms" : "250ms")};
    z-index: 12;

    & > .container {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;

      & > .menu-header {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 10px 15px;
        height: ${props => props.navbarHeight}px;
        justify-content: space-between;
        margin-bottom: 30px;
      }

      & > .nav-item {
        text-transform: uppercase;
        ${HeaderFontCss}
        font-size: 20px;
        letter-spacing: 1px;
        color: ${textColor};
        height: 50px;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        margin-left: 60px;
        margin-bottom: 20px;
      }

      & > .nav-item.active {
      }

      & > .nav-item.contact {
        margin-top: 25px;
        color: #f36a26;
      }
    }
  `,
};

export default connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  ActionCreator
)(Navbar);
