const stringTableTW = {
  welcome: "歡迎!",
  //navbar
  home: "首頁",
  services: "產品",
  history: "事紀",
  about: "關於",
  sharing: "分享",
  project: "經驗",
  product: "服務",
  industry: "領域",

  //navbar for small screen
  "history-mobile": "過去經歷",
  "services-mobile": "產品列表",
  "about-mobile": "關於忻旅",
  "project-mobile": "開發成果",
  "product-mobile": "服務概覽",
  "industry-mobile": "擅長領域",
  "sharing-mobile": "開發分享",

  // landing
  "landing-title": "始自專注，衷於優質",
  slogan: "致力於打通線上線下最後一哩路",
  "landing-title_formula": "始自專注",
  "landing-title_answer": "忠至優質",
  "landing-introduction": "網頁開發 | 手機應用 | 物聯網 | 區塊鏈",
  "landing-description":
    "起步於 2016 年，我們相信科技不該離人們太遠，致力於對接最新科技及商業價值。\n\n用心瞭解客戶需求、 穩扎穩打開發產品，創造出有價值的軟體解決方案。",
  "landing-subtitle-1": "最佳產品",
  "landing-subtitle-2": "完美體驗",
  "landing-subtitle-3": "無縫串連",
  "landing-core-value_title": "拒絕從眾，追求不同",
  "landing-core-value_revolutionary_description":
    "瞬息萬變的今日\n以創意面對未來挑戰",
  "landing-core-value_revenue_description": "探索新商業模式\n開拓前瞻經營視野",
  "landing-core-value_revive_description": "科技不僅是技術\n更是接觸客戶的橋樑",
  "landing-our-partners_title": "跨界結合、全新視野",
  "landing-our-partners_introduction":
    "橫跨超過十個產業\n讓最新技術和商業模式完美對接",
  "see-more-industry": "更多跨界經驗",
  // history
  "history-title": "歷程回望",
  "history-introduction":
    "積極參與及舉辦各式活動論壇，相信一步一腳印的紮實前行定能得到更大的收穫及肯定。若有任何的活動及合作可能，也歡迎您與我們聯絡。",

  "tag-all": "全部",
  "tag-works": "成果",
  "tag-open-source": "開源",
  "tag-report": "軌跡",
  "tag-sharing": "分享",

  // about
  "about-title": "關於忻旅",
  "about-introduction":
    "節奏越來越快的今日，我們面對的是一個快速轉動的世界。新商業模式、新產品需求不停被提出甚至顛覆，如何找到一根定海神針來穩定的創造更高價值是所有局中參與者的共同痛點。\n\n忻旅科技成立於 2016 年，秉持著將最新、最好的軟體導入真實商業世界的簡單理念，開始了這段充滿挑戰的旅程。\n\n在大量的合作和實踐當中，相較於變動劇烈的商業環境，發覺規劃良好的軟體規劃具有可預測性及彈性，我們認為強有力的技術後盾或許會是一個可行的答案。遍及政府機關、傳產、新創及跨國公司的成功合作經驗，也讓我們不懼怕任何挑戰。\n\n忻旅科技將持續保持初心，不斷向前尋找新的可能。",
  "about-contact_title": "公司資訊",
  "about-contact_address": `103 台北市大同區民權西路136號10樓之5`,

  "about-team_title": "團隊介紹",

  // project
  "project-title": "過往經驗",
  "project-introduction":
    "忻旅科技提供超過五十個軟體方案(持續增加中)，橫跨超過十個領域。自上市公司至新創企業、自部分開發至一站式協助皆有服務經驗。\n\n僅列出部分案例供您參考（部分方案不便公開），若有需要或想知道更多細節請聯繫我們。",

  // product
  "product-title": "服務概覽",
  "product-description":
    "軟體開發的本質在於在螢幕的另外一端重新描繪真實世界的行為及交互結構。如何挑選適當技術及何時該改變技術佈局等問題，更標誌了其複雜度不僅是體現在開發，更發生於規劃及後續維運上。\n\n忻旅科技提供完整服務，從初始顧問諮詢、中期實際開發到上限後營運規劃都是我們的協助範圍。",
  "product-title-module": "模組服務",
  "product-description-module":
    "除一站式服務外，忻旅科技亦開發多套模組系統提供整合及訂閱使用",
  "product-modules-list": [
    "APP 推播系統",
    "APP/WEB 會員系統",
    "檔案儲存管理系統",
  ],
  "product-service-step1_title": "諮詢規劃",
  "product-service-step1_from": "商務需求",
  "product-service-step1_to": "可行計畫",
  "product-service-step1_description":
    "在科技快速發展的今天，資訊技術已經成為基礎科學，逐漸滲透進各個專業領域。如何挑選適當的技術及建構佈局來完成既定的商務目標成為一個難解的問題，讓我們以專業的背景及豐富的經歷為您建構最妥適的計畫。",
  "product-service-step2_title": "開發執行",
  "product-service-step2_from": "可行計畫",
  "product-service-step2_to": "實際產品",
  "product-service-step2_description":
    "行動式網頁、雲端運算、物聯網、行動應用再到人工智慧及區塊鏈，這些技術字眼不斷出現在我們的生活周遭，請讓我們的專業團隊做您最堅實的開發後盾，創造最適當的資訊產品以達成您的業務需求。",
  "product-service-step3_title": "營運協力",
  "product-service-step3_from": "實際產品",
  "product-service-step3_to": "商業成功",
  "product-service-step3_description":
    "不同於以往的產品開發流程，現在產品上線往往才是一個新階段的開始。相較於多變的商業環境，產品迭代及維護反而可以被視為是一個相對穩定的營運主心骨，我們致力於提供專業協助讓產品跟您的商業模式一起成長！",
  // industry
  "industry-title": "擅長領域",
  "tech-good": "技術專長",
  "industry-description":
    "iPhone 誕生標誌了載體行業的典範轉移，軟體技術也跟著如浪潮般紛紛湧來。將這些新技術結合到既有的商業場域變得比以前更加困難，不只要能掌握技術動態亦要能理解業務需求。\n\n作為一站式軟體方案提供商的我們，在行動網頁、手機應用程式、物聯網軟硬整合到區塊鏈技術都有涉獵及導入的經驗。",
  "industry-content_title_1": " 以專業經驗 ",
  "industry-content_title_2": " 助價值提升 ",
  "industry-item_title_medical": "醫療",
  "industry-item_title_finance": "金融",
  "industry-item_title_blockchain": "區塊鏈",
  "industry-item_title_education": "教育",
  "industry-item_title_ecommerce": "電商",
  "industry-item_title_fitness": "健身",
  "industry-item_title_website": "官網",
  "industry-item_title_logistic": "物流",
  "industry-item_title_travel": "旅宿",
  "industry-item_title_art": "藝文",
  "industry-item_title_postpartum": "月子中心",
  "industry-item_title_tranditindustry": "傳統產業",
  "industry-item_title_npo": "非營利組織",
  //contact form
  "contact-title": "聯絡我們",
  "contact-description":
    "請透過表單留下基本資訊與諮詢內容\n我們將以電子郵件或是電話與您聯絡，謝謝！",
  "contact-field-name": "聯絡人姓名",
  "contact-field-firm": "企業名稱",
  "contact-field-email": "電子郵件",
  "contact-field-phone": "聯絡電話",
  "contact-field-type": "需求類型",
  "contact-field-type-option-app": "APP開發諮詢",
  "contact-field-type-option-web": "網站開發諮詢",
  "contact-field-type-option-ecommerrce": "電商開發諮詢",
  "contact-field-type-option-system": "系統開發諮詢",
  "contact-field-type-option-other": "其他顧問諮詢",
  "contact-field-requirement": "需求說明",
  "contact-send": "送出",
  "contact-hint": "我們將會在1-2個工作日由專人回覆您，謝謝！",

  // general
  "contact-us": "聯絡我們",
  "see-more": "查看更多",
  "read-more": "了解更多",
};

export default {
  display: "正體中文",
  key: "tw",
  stringTable: stringTableTW,
};
